import MainView from "../views/MainView.vue"
import AuthView from "../views/AuthView.vue"
import {User, Video, GraduationCap, Award, Wallet} from "lucide-vue-next"

export const routes = [
  {
    path: "/",
    name: "MainView",
    component: MainView,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: "dashboard",
        name: "DashboardView",
        component: () => import("../views/DashboardView.vue"),
        meta: {
          requiresAuth: true,
          pageTitle: "Dashboard",
        },
      },
      {
        path: "messaging-ai",
        name: "MessagingAIView",
        component: () => import("../views/MessagingAIView.vue"),
        meta: {
          requiresAuth: true,
          pageTitle: "AI chat",
        },
      },
      {
        path: "messaging-provider",
        name: "MessagingProviderView",
        component: () => import("../views/MessagingProviderView.vue"),
        meta: {
          requiresAuth: true,
          pageTitle: "Chat",
        },
        children: [
          {
            path: "conversations/:conversationId",
            name: "MessagingConversationsDetailView",
            component: () => import("../views/MessagingConversationsView.vue"),
            meta: {
              requiresAuth: true,
              backRouteName: "MessagingProviderView",
            },
          },
          {
            path: "conversations",
            name: "MessagingConversationsView",
            component: () => import("../views/MessagingConversationsView.vue"),
            meta: {
              requiresAuth: true,
            },
          },
          {
            path: "conversations/:conversationId?/messages/:messageId?",
            name: "MessagingConversationsMessagesView",
            component: () => import("../views/MessagingConversationsView.vue"),
            meta: {
              requiresAuth: true,
            },
          },
          {
            path: "conversations/new",
            name: "MessagingConversationsNewView",
            component: () => import("../views/MessagingConversationsView.vue"),
            meta: {
              requiresAuth: true,
            },
          },
        ],
      },
      {
        path: "schedule",
        name: "ScheduleView",
        component: () => import("../views/ScheduleView.vue"),
        meta: {
          requiresAuth: true,
          pageTitle: "Schedule",
        },
        children: [
          {
            path: ":slotId",
            name: "ScheduleDetailView",
          },
        ],
      },
      {
        path: "clients",
        name: "ClientsView",
        component: () => import("../views/ClientsView.vue"),
        meta: {
          requiresAuth: true,
          pageTitle: "Clients",
        },
        children: [
          {
            path: ":clientId",
            name: "ClientsDetailView",
            component: () => import("../views/ClientsDetailView.vue"),
            meta: {
              requiresAuth: true,
              pageTitle: "Client detail",
              backRouteName: "ClientsView",
            },
            children: [
              {
                path: "sessions/:sessionId/:tabId(appointment|notesPrivate|notesShared)?",
                name: "ClientsDetailSessionsDetailView",
                component: () => import("../views/ClientsDetailSessionsDetailView.vue"),
                meta: {
                  requiresAuth: true,
                  pageTitle: "Session",
                  backRouteName: "ClientsDetailView",
                },
              },
              {
                path: "sessions/:sessionId/evaluation",
                name: "ClientsDetailSessionsDetailEvaluationView",
                component: () => import("../views/SessionsDetailEvaluationView.vue"),
                meta: {
                  requiresAuth: true,
                  pageTitle: "Session evaluation",
                  backRouteName: "ClientsDetailSessionsDetailView",
                },
              },
            ],
          },
        ],
      },
      {
        path: "providers",
        name: "ProvidersView",
        component: () => import("../views/ProvidersView.vue"),
        meta: {
          requiresAuth: true,
          pageTitle: "Providers",
        },
        children: [
          {
            path: ":providerId",
            name: "ProvidersDetailView",
            component: () => import("../views/ProvidersDetailView.vue"),
            meta: {
              requiresAuth: true,
              pageTitle: "Provider",
              backRouteName: "ProvidersView",
            },
            children: [
              {
                path: "schedule/:slotId?",
                name: "ProvidersDetailScheduleView",
                component: () => import("../views/ProvidersDetailScheduleView.vue"),
                meta: {
                  requiresAuth: true,
                  pageTitle: "Provider schedule",
                  backRouteName: "ProvidersDetailView",
                },
              },
            ],
          },
        ],
      },
      {
        path: "sessions",
        name: "SessionsView",
        component: () => import("../views/SessionsView.vue"),
        meta: {
          requiresAuth: true,
          pageTitle: "Sessions",
        },
        children: [
          {
            path: ":sessionId",
            name: "SessionsDetailView",
            component: () => import("../views/SessionsDetailView.vue"),
            meta: {
              requiresAuth: true,
              pageTitle: "Session",
              backRouteName: "SessionsView",
            },
            children: [
              {
                path: ":tabId(appointment|notesPrivate|notesShared)?",
                name: "SessionsDetailView",
              },
              {
                path: "confirmed",
                name: "SessionsDetailConfirmedView",
                meta: {
                  requiresAuth: true,
                  pageTitle: "Session",
                  backRouteName: "SessionsView",
                },
              },
              {
                path: "call",
                name: "SessionDetailCallView",
                component: () => import("../views/SessionsDetailCallView.vue"),
              },
              {
                path: "evaluation",
                name: "SessionsDetailEvaluationView",
                component: () => import("../views/SessionsDetailEvaluationView.vue"),
                meta: {
                  requiresAuth: true,
                  pageTitle: "Session evaluation",
                  backRouteName: "SessionsDetailView",
                },
              },
            ],
          },
        ],
      },
      {
        path: "sign-in-as",
        name: "SignInAsView",
        component: () => import("../views/SignInAsView.vue"),
        meta: {
          requiresAuth: true,
          pageTitle: "Sign in as",
        },
      },
      {
        path: "profile",
        name: "ProfileView",
        component: () => import("../views/ProfileView.vue"),
        meta: {
          requiresAuth: true,
          pageTitle: "Profile",
        },
        children: [
          {
            path: "info",
            name: "ProfileInfoView",
            component: () => import("../views/ProfileInfoView.vue"),
            meta: {
              requiresAuth: true,
              pageTitle: "My info",
              icon: User,
              backRouteName: "ProfileView",
              roles: ["provider", "client"],
            },
          },
          {
            path: "intro",
            name: "ProfileIntroView",
            component: () => import("../views/ProfileIntroView.vue"),
            meta: {
              requiresAuth: true,
              pageTitle: "My intro video",
              backRouteName: "ProfileView",
              icon: Video,
              roles: ["provider"],
            },
          },
          {
            path: "educations",
            name: "ProfileEducationsView",
            component: () => import("../views/ProfileEducationsView.vue"),
            meta: {
              requiresAuth: true,
              pageTitle: "Education",
              backRouteName: "ProfileView",
              icon: GraduationCap,
              roles: ["provider"],
            },
            children: [
              {
                path: "add",
                name: "ProfileEducationsAddView",
                component: () => import("../views/ProfileEducationsDetailView.vue"),
                meta: {
                  requiresAuth: true,
                  pageTitle: "Add education",
                  backRouteName: "ProfileEducationsView",
                  roles: ["provider"],
                },
              },
              {
                path: ":educationId",
                name: "ProfileEducationsDetailView",
                component: () => import("../views/ProfileEducationsDetailView.vue"),
                meta: {
                  requiresAuth: true,
                  pageTitle: "Education detail",
                  backRouteName: "ProfileEducationsView",
                  roles: ["provider"],
                },
              },
            ],
          },
          {
            path: "licenses",
            name: "ProfileLicensesView",
            component: () => import("../views/ProfileLicensesView.vue"),
            meta: {
              requiresAuth: true,
              pageTitle: "License",
              backRouteName: "ProfileView",
              icon: Award,
              roles: ["provider"],
            },
            children: [
              {
                path: "add",
                name: "ProfileLicensesAddView",
                component: () => import("../views/ProfileLicensesDetailView.vue"),
                meta: {
                  requiresAuth: true,
                  pageTitle: "Add license",
                  backRouteName: "ProfileLicensesView",
                  roles: ["provider"],
                },
              },
              {
                path: ":licenseId",
                name: "ProfileLicensesDetailView",
                component: () => import("../views/ProfileLicensesDetailView.vue"),
                meta: {
                  requiresAuth: true,
                  pageTitle: "License detail",
                  backRouteName: "ProfileLicensesView",
                  roles: ["provider"],
                },
              },
            ],
          },
          {
            path: "insurances",
            name: "ProfileInsurancesView",
            component: () => import("../views/ProfileInsurancesView.vue"),
            meta: {
              requiresAuth: true,
              pageTitle: "Insurance",
              backRouteName: "ProfileView",
              icon: Award,
              roles: ["provider"],
            },
            children: [
              {
                path: "add",
                name: "ProfileInsurancesAddView",
                component: () => import("../views/ProfileInsurancesDetailView.vue"),
                meta: {
                  requiresAuth: true,
                  pageTitle: "Add insurance",
                  backRouteName: "ProfileInsurancesView",
                  roles: ["provider"],
                },
              },
              {
                path: ":insuranceId",
                name: "ProfileInsurancesDetailView",
                component: () => import("../views/ProfileInsurancesDetailView.vue"),
                meta: {
                  requiresAuth: true,
                  pageTitle: "Insurance detail",
                  backRouteName: "ProfileInsurancesView",
                  roles: ["provider"],
                },
              },
            ],
          },
          {
            path: "Identifications",
            name: "ProfileIdentificationsView",
            component: () => import("../views/ProfileIdentificationsView.vue"),
            meta: {
              requiresAuth: true,
              pageTitle: "Identification",
              backRouteName: "ProfileView",
              icon: Award,
              roles: ["provider"],
            },
            children: [
              {
                path: "add",
                name: "ProfileIdentificationsAddView",
                component: () => import("../views/ProfileIdentificationsDetailView.vue"),
                meta: {
                  requiresAuth: true,
                  pageTitle: "Add Identification",
                  backRouteName: "ProfileIdentificationsView",
                  roles: ["provider"],
                },
              },
              {
                path: ":identificationId",
                name: "ProfileIdentificationsDetailView",
                component: () => import("../views/ProfileIdentificationsDetailView.vue"),
                meta: {
                  requiresAuth: true,
                  pageTitle: "Identification Detail",
                  backRouteName: "ProfileIdentificationsView",
                  roles: ["provider"],
                },
              },
            ],
          },
          {
            path: "billing",
            name: "ProfileBillingView",
            component: () => import("../views/ProfileBillingView.vue"),
            meta: {
              requiresAuth: true,
              pageTitle: "Billing",
              backRouteName: "ProfileView",
              icon: Wallet,
              roles: ["provider"],
            },
          },
        ],
      },
    ],
  },
  {
    path: "/join",
    name: "JoinView",
    component: () => import("../views/JoinView.vue"),
    children: [
      {
        path: ":role(provider|client)/:stageId?",
        name: "JoinQuestionnaireView",
        component: () => import("../views/JoinQuestionnaireView.vue"),
      },
      {
        path: ":role(provider|client)/intro",
        name: "JoinIntroView",
        component: () => import("../views/JoinIntroView.vue"),
      },
      {
        path: ":role(provider|client)/signup",
        name: "JoinSignupView",
        component: () => import("../views/JoinSignupView.vue"),
      },
    ],
  },
  {
    path: "/auth",
    name: "AuthView",
    component: AuthView,
  },
]
